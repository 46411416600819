<template>
  <span v-if="old" class="base-loader" />
  <base-loader-progress v-else :big="big" :primary="primary" :secondary="secondary" />
</template>

<script>
import BaseLoaderProgress from '#root/src/components/BaseComponents/BaseLoaderProgress.vue';

export default {
  components: { BaseLoaderProgress },
  props: {
    old: {
      type: Boolean,
      default() {
        return true;
      },
    },
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    big: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.base-loader {
  &::after {
    content: ' ';
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('@/assets/img/progress-circle.png');
    background-size: cover;
    animation: rotation 2s infinite linear;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-359deg);
  }
}
</style>
